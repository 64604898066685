import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '~/lib/styles';

const bannerVariants = cva(
  'flex flex-col gap-1.5 rounded-lg border border-l-4 px-4 py-3 text-sm',
  {
    variants: {
      variant: {
        default: 'border-border border-l-primary bg-muted',
        brandPrimary:
          'border-burnt-sienna-200 border-l-brand-primary bg-burnt-sienna-50',
        info: 'border-regal-blue-200 border-l-info bg-regal-blue-50',
        success: 'border-spring-green-200 border-l-success bg-spring-green-50',
        warn: 'border-yellow-200 border-l-warn bg-yellow-50',
        destructive: 'border-red-200 border-l-destructive bg-red-50'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface BannerProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof bannerVariants> {}

export function Banner({ className, children, variant, ...rest }: BannerProps) {
  return (
    <div className={cn(bannerVariants({ className, variant }))} {...rest}>
      {children}
    </div>
  );
}
